/* eslint-disable */
import React, { createContext, useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { permissionsObject } from './defaultValues';
import { baseUrl } from './defaultValues';
import { accessToken } from './defaultValues';

export const UserContext = createContext(); 

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserPermissions, setCurrentUserPermissions] = useState(null);
  const [token, setToken] = useState(null);
  const [globalAccount, setGlobalAccount] = useState(null);
  const [allAccounts, setAllAccounts] = useState([]);
  const [csrfToken, setCsrfToken] = useState(null);
  const [auth, setAuth] = useState(false);
  // const [account, setAccount] = useState(4);
  const [account, setAccount] = useState(null);
  const [cookieValue, setCookieValue] = useState('');

  const getAccounts = async () => {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}`, 'x-csrftoken': `${Cookies.get('csrftoken')}` }
    };
    try {
      const response = await axios.get(
        `${baseUrl}/api/v1/providers/accounts`,
        config,
      );
      if (response.status === 200) {
        const { data } = response.data;
        if(data){
          setAccount(data[0].id);
        }
      }
    } catch (error) {
      if (error.response) {
        // The request was made, and the server responded with a status code outside of the 2xx range.
        console.log('Response Data:', error.response.data);
      } else if (error.request) {
        // The request was made, but no response was received (e.g., network error).
        console.log('Request Error:', error.request);
      } else {
        // Something happened in setting up the request that triggered an error.
        console.log('Request Setup Error:', error.message);
      }
      console.log('Error Config:', error.config);
    }
  };
  
  useEffect(() => {
    console.log(account);
    if(!account)
    {
      getAccounts();
    }

  }, []);

  useEffect(() => {
    setCurrentUserPermissions(permissionsObject);
  }, []);

  const updateUser = (value) => {
    setUser(value);
  };

  const updateToken = (value) => {
    setToken(value);
  };

  const updateCsrfToken = (value) => {
    setCsrfToken(value);
  };

  const updateAuth = (value) => {
    setAuth(value);
  };

  const updateAccount = (value) => {
    setAccount(value);
  };

  const getCsrfToken = async () => {
    const accessToken = localStorage.getItem('accessToken');
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    try {
      const response = await axios.get(`${baseUrl}/api/v2/csrf/token`, config);
      if (response.status === 200) {
        localStorage.setItem('csrftoken', response.data.csrfToken);
        Cookies.set('csrftoken', response.data.csrfToken);
        setCsrfToken(response.data.csrfToken);
      }
    } catch (error) {
      console.log('Error Csrf Token:', error);
    }
  };

  useEffect(() => {
    const jwttoken = localStorage.getItem('accessToken');
    const csrf = localStorage.getItem('csrftoken');
    if(!csrf)
    {
      getCsrfToken();
    }
    if (jwttoken) {
      updateToken(jwttoken);
      setCsrfToken(csrf);
      updateAuth(true);
      getCsrfToken();
    } else {
      Redirect('/login');
      updateToken(null);
      updateAuth(false);
      localStorage.clear();
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        currentUser, 
        setCurrentUser,
        globalAccount,
        setGlobalAccount,
        currentUserPermissions,
        setCurrentUserPermissions,
        updateUser,
        updateToken,
        token,
        allAccounts,
        setAllAccounts,
        account,
        setAccount,
        updateAuth,
        auth,
        updateAccount,
        account,
        updateCsrfToken,
        csrfToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};


export default UserProvider;
