/* eslint-disable */
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import reportWebVitals from './reportWebVitals';
import UserProvider from './constants/UserContext';
import './App.css';

import { registerLicense } from '@syncfusion/ej2-base';

// Set your Syncfusion license key here
const syncfusionLicenseKey = 'Ngo9BigBOggjHTQxAR8/V1NHaF1cWWhIfExxWmFZfV1gcl9CYVZSRWYuP1ZhSXxQd0RgWX9YdHVWR2ZdUUY=';
registerLicense(syncfusionLicenseKey);

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

const Main = () => {
  return (
    // <React.StrictMode>
      <UserProvider>
        <Provider store={configureStore()}>
          <Suspense fallback={<div className="loading" />}>
            <App />
          </Suspense>
        </Provider>
      </UserProvider>
    // </React.StrictMode>
  );
};

ReactDOM.render(<Main />, document.getElementById('root'));


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
